import React, { useState, useEffect } from 'react';
import { string, object, func, oneOfType } from 'prop-types';
import { propTypes } from '../../util/types';
import moment from 'moment';

import css from './NextBookingCard.module.css';

const NextBookingCard = props => {
  const { rootClassName, className, booking } = props;

  const labelise = key => {
    const options = [
      { key: 'New', label: 'New request' },
      { key: 'Closed Won', label: 'Confirmed' },
      { key: 'Closed Lost', label: 'Cancelled' },
    ];
    const keys = options.map(a => a.key);
    if (!keys.includes(key)) return key;
    return options.filter(a => a.key == key)[0].label ?? key;
  };
  const timeShave = time => {
    return time.replace(/(\d{1,2}\W*:\W*\d{2})\W*:\W*\d{2}/, '$1');
  };
  return (
    <div className={css.bookingCard}>
      <div className={css.info}>
        <div className={css.start}>
          <div>
            {' '}
            <h3>Booking reference {booking.reference}</h3>
            <p>
              {`${moment(booking.date).format('DD/MM/YYYY')} ${timeShave(booking.hour)}-
              ${timeShave(booking.hour9)}`}
            </p>
          </div>
          {booking.booking_type && (
            <div>
              <h3>Booking Type:</h3>
              <p>{booking.booking_type}</p>
            </div>
          )}
          <div>
            <h3>Professional:</h3>
            <p>{booking.type_of_professional}</p>
          </div>{' '}
          <div>
            <h3>Description: </h3>
            <p>{booking.session_notes}</p>
          </div>
          {booking.relationship == 'commProf' ||
            (booking.connect_boards6 != 'undefined undefined' && (
              <div>
                <h3>Professional booked:</h3>
                {booking.relationship == 'commProf'
                  ? fullName
                  : booking.connect_boards6 == 'undefined undefined'
                  ? 'TBC'
                  : booking.connect_boards6 ?? 'TBC'}
              </div>
            ))}
        </div>
        <div className={css.end}>
          <div>
            {' '}
            <h3>Status: </h3>
            <p>{labelise(booking.status7) ?? 'TBC'}</p>
          </div>

          <div>
            <h3>Location</h3>
            <p>
              {booking.locationName.length > 0
                ? booking.locationName
                : booking.location.length > 0
                ? booking.location
                : 'Online'}
            </p>
          </div>
          {booking.locationDepartment.length > 0 ? (
            <div>
              <h3>Department</h3>
              <p> {booking.locationDepartment} </p>
            </div>
          ) : null}
          {booking.relationship == 'commProf' && (
            <div>
              <h3>Booking Fee:</h3>
              <p>{booking?.fee.length > 0 ? `£${booking.fee}` : 'TBC'}</p>
            </div>
          )}
          {booking.relationship == 'commProf' && (
            <div>
              <h3>Travel Fee:</h3>
              <p> {booking?.travelfee.length > 0 ? `£${booking.travelfee}` : 'TBC'}</p>
            </div>
          )}
          {!!booking.amount && (
            <div>
              <h3>Amount: </h3>
              <p>{booking.amount != '£0.00' ? booking.amount : 'TBC'}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

NextBookingCard.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
};

NextBookingCard.propTypes = {
  rootClassName: string,
  className: string,
  booking: object,
};

export default NextBookingCard;
