import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import hand from './image/hand.png';
import { checkVtiger } from '../../util/api';
import css from './SectionHero.module.css';
import { connect, useDispatch } from 'react-redux';
import { P } from '../../containers/PageBuilder/Primitives/P';
import { updateHasVtiger } from './SectionHero.duck';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [hasVtiger, setHasVtiger] = useState(null);
  const [requiresVtiger, setRequiresVtiger] = useState(null);
  const { rootClassName, className, currentUser, markVtiger } = props;
  const userType = currentUser?.attributes?.profile?.privateData?.userTypes;
  const isUserProvider = userType?.includes('communicationProfessional');
  const dispatch = useDispatch();

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);
  const isAuthenticated = !!currentUser?.id;
  const checkForFlag = async () => {
    //if already ran, break
    if (requiresVtiger == false) return;
    //if not logged in, break
    if (!isAuthenticated) {
      setRequiresVtiger(false);
      setHasVtiger(false);
      return;
    }
    //if marked on sharetribe, break (cuts down on API calls)
    if (
      currentUser?.attributes?.profile?.publicData?.hasVtiger == true ||
      currentUser?.attributes?.profile?.publicData?.hasVtiger == 'true'
    ) {
      setRequiresVtiger(false);
      setHasVtiger(true);
      return;
    }
    // look for user on Vtiger, and if necessary, create them
    try {
      //console.log('checking CRM');
      let response = await checkVtiger();
      setHasVtiger(response.exists);
      setRequiresVtiger(true);
    } catch (err) {
      console.log(err);
    }
  };

  // run only when currentUser is updated
  useEffect(() => {
    checkForFlag();
  }, [currentUser]);

  if (requiresVtiger & hasVtiger) {
    // if vTiger has been located/found, mark it in Flex to save on future API calls
    //console.log('marking CRM');
    markVtiger();
    setRequiresVtiger(false);
  }
  const notCustomer = ['deafUser', 'communicationProfessional'];
  const viewBookingsOnly =
    userType && notCustomer.some(a => userType.includes(a)) && !userType.includes('customer');
  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.investHero" />
            {/* <FormattedMessage id="SectionHero.title" /> */}
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <div className={css.callsToAction}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  'address=Merseyside%2C%20England%2C%20United%20Kingdom&bounds=53.89%2C-2.16%2C53.06%2C-3.37&pub_listingtype=listing',
              }}
              className={css.heroButtonSecondary}
            >
              <FormattedMessage id="SectionHero.browseButton" />
            </NamedLink>
            {
              // isAuthenticated && isUserProvider ? (
              //   <NamedLink
              //     name="SearchPage"
              //     to={{
              //       search: 'pub_listingtype=requirement',
              //     }}
              //     className={css.heroButton}
              //   >
              //     <FormattedMessage id="SectionHero.browseBookingsButton" />
              //   </NamedLink>
              // ) :
              // !viewBookingsOnly ? (
              //   <NamedLink className={css.heroButton} name="CreateRequirementPage">
              //     <FormattedMessage id="TopbarDesktop.createRequirementListing" />
              //   </NamedLink>
              // ) : null
            }
            <NamedLink name="CMSPage" params={{ pageId: 'invest' }} className={css.heroButton}>
              <FormattedMessage id="TopbarDesktop.toInvestPage" />
            </NamedLink>
          </div>
        </div>
        <img fetchpriority="high" src={hand} alt="Signalise hand" className={css.heroHand} />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({ markVtiger: () => dispatch(updateHasVtiger()) });

export default connect(mapStateToProps, mapDispatchToProps)(SectionHero);
